<template>
   <v-card
      class="mt-2"
      color="#fff"
      style="width: 180px; height: 180px; overflow: hidden"
      @mouseover="hover = true"
      @mouseleave="hover = false"
   >
      <v-card :color="color" dark>
         <v-expand-transition>
            <div
               v-if="hover"
               class="d-flex transition-fast-in-fast-out v-card--reveal text-subtitle-1 white--text justify-center"
               :style="'background-color:' + color"
            >
               {{ texto }}
            </div>
         </v-expand-transition>
         <v-card-title class="text-subtitle-2">
            <v-layout wrap>
               <v-flex xs6 class="d-flex justify-start">
                  {{ correlativo }}
               </v-flex>
               <v-flex xs6 class="d-flex justify-end">
                  {{ codigoMateria }}
               </v-flex>
            </v-layout>
         </v-card-title>
      </v-card>
      <v-card-title
         class="text-subtitle-2 justify-center"
         style="height: 75px; overflow: hidden"
      >
         {{ materia }}
      </v-card-title>
      <v-spacer></v-spacer>
      <v-list class="transparent">
         <v-list-item
            v-if="
               !(estado === 'R' && parseInt(nota) + parseInt(asistencia) == 0)
            "
         >
            <v-list-item-title class="text-left">{{ nota }}</v-list-item-title>
            <v-list-item-title class="text-right">
               {{ asistencia }}%
            </v-list-item-title>
         </v-list-item>
      </v-list>
   </v-card>
</template>

<script>
export default {
   name: 'ItemPensum',
   props: {
      correlativo: {
         type: Number,
         default: 0
      },
      codigoMateria: {
         type: String,
         default: 'TEO100'
      },
      materia: {
         type: String,
         default: 'METODOS Y TECNICAS DE INVESTIGACION'
      },
      nota: {
         type: Number,
         default: 9.9
      },
      estado: {
         type: String,
         default: 'A'
      },
      color: {
         type: String,
         default: '#8BCDCD'
      },
      condicion: {
         type: Number,
         default: 0
      },
      asistencia: {
         type: Number,
         default: 100
      },
      texto: {
         type: String,
         default: 'Aprobada'
      }
   },
   data() {
      return {
         hover: false
      }
   }
}
</script>
