<template>
   <v-layout align-start>
      <v-flex xs12>
         <v-simple-table>
            <template #default>
               <thead>
                  <tr>
                     <th v-for="ciclo in pensum.ciclos" :key="ciclo.ciclo">
                        <div class="text-h6" style="text-align: center">
                           Ciclo {{ ciclo.ciclo }}
                        </div>
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <tr v-for="num in maxMateria" :key="num" class="text-center">
                     <td v-for="ciclo in pensum.ciclos" :key="ciclo.ciclo">
                        <div v-if="ciclo.materias[num - 1]" class="my-4">
                           <ItemPensum
                              :materia="ciclo.materias[num - 1].materia"
                              :correlativo="ciclo.materias[num - 1].correlativo"
                              :codigo-materia="
                                 ciclo.materias[num - 1].codigoMateria
                              "
                              :nota="ciclo.materias[num - 1].nota"
                              :asistencia="ciclo.materias[num - 1].asistencia"
                              :estado="ciclo.materias[num - 1].estado"
                              :color="getColor(ciclo.materias[num - 1])"
                              :texto="getTexto(ciclo.materias[num - 1])"
                           ></ItemPensum>
                        </div>
                        <div v-else>
                           <div></div>
                        </div>
                     </td>
                  </tr>
               </tbody>
            </template>
         </v-simple-table>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import ItemPensum from './custom/ItemPensum.vue'
import { authComputed } from '../helpers'

export default {
   name: 'Pensum',
   components: {
      ItemPensum: ItemPensum
   },
   props: {
      carnet: {
         type: String,
         default: '2015-AC-801'
      }
   },

   data: () => ({
      pensum: {
         carnet: '2015-AC-801',
         codigoCarrera: 3410,
         carrera: 'LICENCIATURA EN SISTEMAS INFORMATICOS ADMINISTRATIVOS',
         ciclos: [
            {
               ciclo: 1,
               materias: [
                  {
                     correlativo: 1,
                     codigoMateria: 'TEO100',
                     materia: 'TEOLOGIA I',
                     nota: 9.9,
                     condicion: 0,
                     estado: 'R',
                     asistencia: 100
                  },
                  {
                     correlativo: 2,
                     codigoMateria: 'FIG100',
                     materia: 'FILOSOFIA GENERAL',
                     nota: 9.5,
                     condicion: 0,
                     estado: 'R',
                     asistencia: 100
                  },
                  {
                     correlativo: 3,
                     codigoMateria: 'DPE100',
                     materia: 'DESARROLLO PERSONAL',
                     nota: 0,
                     condicion: 0,
                     estado: 'R',
                     asistencia: 0
                  },
                  {
                     correlativo: 4,
                     codigoMateria: 'LYC100',
                     materia: 'LECTURA Y COMPOSICION',
                     nota: 9.8,
                     condicion: 2,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 5,
                     codigoMateria: 'MBC102',
                     materia: 'MANEJO BASICO DE COMPUTADORAS',
                     nota: 9.8,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  }
               ]
            },
            {
               ciclo: 2,
               materias: [
                  {
                     correlativo: 6,
                     codigoMateria: 'TEO200',
                     materia: 'TEOLOGIA II',
                     nota: 9.9,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 7,
                     codigoMateria: 'ETS100',
                     materia: 'ETICA SOCIAL',
                     nota: 9.9,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 8,
                     codigoMateria: 'SIO102',
                     materia: 'SISTEMAS OPERATIVOS',
                     nota: 9.8,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 9,
                     codigoMateria: 'TEG102',
                     materia: 'TEORIA GERENCIAL I',
                     nota: 9.5,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 10,
                     codigoMateria: 'IAE102',
                     materia: 'INTRODUCCION A LA ECONOMIA',
                     nota: 9.8,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  }
               ]
            },
            {
               ciclo: 3,
               materias: [
                  {
                     correlativo: 11,
                     codigoMateria: 'PRE102',
                     materia: 'PROGRAMACION ESTRUCTURADA',
                     nota: 9.7,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 12,
                     codigoMateria: 'SAB102',
                     materia: 'SISTEMAS ABIERTOS I',
                     nota: 9.7,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 13,
                     codigoMateria: 'MAT124',
                     materia: 'MATEMATICA I',
                     nota: 10,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 98
                  },
                  {
                     correlativo: 14,
                     codigoMateria: 'CNT102',
                     materia: 'CONTABILIDAD',
                     nota: 9.7,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  }
               ]
            },
            {
               ciclo: 4,
               materias: [
                  {
                     correlativo: 15,
                     codigoMateria: 'BAD102',
                     materia: 'BASE DE DATOS',
                     nota: 9.6,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 16,
                     codigoMateria: 'SAB202',
                     materia: 'SISTEMAS ABIERTOS II',
                     nota: 9.9,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 17,
                     codigoMateria: 'MAT224',
                     materia: 'MATEMATICA II',
                     nota: 9.4,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 18,
                     codigoMateria: 'EST124',
                     materia: 'ESTADISTICA I',
                     nota: 8.7,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  }
               ]
            },
            {
               ciclo: 5,
               materias: [
                  {
                     correlativo: 19,
                     codigoMateria: 'GBA102',
                     materia: 'GESTION DE BASE DE DATOS I',
                     nota: 9.8,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 20,
                     codigoMateria: 'POO102',
                     materia: 'PROGRAMACION ORIENTADA A OBJETOS I',
                     nota: 9.6,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 21,
                     codigoMateria: 'MAF102',
                     materia: 'MATEMATICA FINANCIERA',
                     nota: 9.4,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 22,
                     codigoMateria: 'EST202',
                     materia: 'ESTADISTICA II',
                     nota: 9.9,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 23,
                     codigoMateria: 'MYT100',
                     materia: 'METODOS Y TECNICAS DE INVESTIGACION',
                     nota: 9.9,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  }
               ]
            },
            {
               ciclo: 6,
               materias: [
                  {
                     correlativo: 24,
                     codigoMateria: 'GBA202',
                     materia: 'GESTION DE BASE DE DATOS II',
                     nota: 9.7,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 25,
                     codigoMateria: 'POO202',
                     materia: 'PROGRAMACION ORIENTADA A OBJETOS II',
                     nota: 9.9,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 26,
                     codigoMateria: 'GFI102',
                     materia: 'GESTION FINANCIERA',
                     nota: 10,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 27,
                     codigoMateria: 'GME102',
                     materia: 'GESTION DE MERCADEO',
                     nota: 9.4,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 28,
                     codigoMateria: 'LEM124',
                     materia: 'LEGISLACION EMPRESARIAL',
                     nota: 9.3,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  }
               ]
            },
            {
               ciclo: 7,
               materias: [
                  {
                     correlativo: 29,
                     codigoMateria: 'GBA302',
                     materia: 'GESTION DE BASE DE DATOS III',
                     nota: 9.9,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 30,
                     codigoMateria: 'PRW102',
                     materia: 'PROGRAMACION EN WEB I',
                     nota: 9.6,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 31,
                     codigoMateria: 'GDP102',
                     materia: 'GESTION DE PERSONAL',
                     nota: 9.3,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 32,
                     codigoMateria: 'SOR102',
                     materia: 'SISTEMAS ORGANIZACIONALES',
                     nota: 9.2,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  }
               ]
            },
            {
               ciclo: 8,
               materias: [
                  {
                     correlativo: 33,
                     codigoMateria: 'PIN102',
                     materia: 'PROYECTOS INFORMATICOS I',
                     nota: 9.5,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 34,
                     codigoMateria: 'PRW202',
                     materia: 'PROGRAMACION EN WEB II',
                     nota: 9.9,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 35,
                     codigoMateria: 'ETI100',
                     materia: 'ETICA',
                     nota: 10,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 36,
                     codigoMateria: 'AET102',
                     materia: 'ADMINISTRACION ESTRATEGICA',
                     nota: 8.5,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 37,
                     codigoMateria: 'ADR102',
                     materia: 'ADMINISTRACION DE REDES',
                     nota: 9.4,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  }
               ]
            },
            {
               ciclo: 9,
               materias: [
                  {
                     correlativo: 38,
                     codigoMateria: 'PRI202',
                     materia: 'PROYECTOS INFORMATICOS II',
                     nota: 9.4,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 99
                  },
                  {
                     correlativo: 39,
                     codigoMateria: 'DES102',
                     materia: 'DESARROLLO DE SOFTWARE',
                     nota: 9.8,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 40,
                     codigoMateria: 'GIN102',
                     materia: 'GERENCIA DE INFORMATICA',
                     nota: 9.5,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  },
                  {
                     correlativo: 41,
                     codigoMateria: 'FYE124',
                     materia: 'FORMULACION Y EVALUACION DE PROYECTOS',
                     nota: 9,
                     condicion: 0,
                     estado: 'A',
                     asistencia: 100
                  }
               ]
            },
            {
               ciclo: 11,
               materias: [
                  {
                     correlativo: 42,
                     codigoMateria: 'TDG0006',
                     materia: 'TRABAJO DE GRADUACION',
                     nota: 0,
                     condicion: 0,
                     estado: 'R',
                     asistencia: 0
                  }
               ]
            }
         ]
      }
   }),
   computed: {
      ...authComputed,
      show() {
         return this.pensum
      },
      maxMateria() {
         let max = 0
         if (this.show && this.pensum.ciclos != undefined) {
            for (let i = 0; i < this.pensum.ciclos.length; i++) {
               max =
                  this.pensum.ciclos[i].materias.length > max
                     ? this.pensum.ciclos[i].materias.length
                     : max
            }
         }
         return max
      }
   },
   watch: {
      carnet(val) {
         if (val.length == 11) {
            this.listarPensum()
         }
      }
   },
   created() {
      this.listarPensum()
   },
   methods: {
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarPensum() {
         this.pensum = []
         // Obteniendo pensum
         axios
            .get('api/SolicitudDeBeca/Pensum?carnet=' + this.carnet)
            .then((response) => {
               this.pensum = response.data
               console.log(this.pensum)
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Si no cargaron bien las pensum en el datatable se puede resetear
      reset() {
         this.listarPensum()
      },
      getColor(materia) {
         let color = ''
         if (materia.condicion > 0) {
            color = '#FFC947'
         } else if (materia.estado === 'A') {
            color = '#206A5D'
         } else if (
            materia.estado === 'R' &&
            parseInt(materia.nota) + parseInt(materia.asistencia) > 0
         ) {
            color = '#CE1212'
         } else {
            color = '#A1CAE2'
         }

         return color
      },
      getTexto(materia) {
         if (materia.condicion > 0) {
            return 'Equiv. condicionada'
         } else if (materia.estado === 'A') {
            return 'Aprobada'
         } else if (
            materia.estado === 'R' &&
            parseInt(materia.nota) + parseInt(materia.asistencia) > 0
         ) {
            return 'Reprobada'
         } else {
            return 'Pendiente de cursar'
         }
      }
   }
}
</script>

<style scoped>
tr:hover {
   background-color: transparent !important;
}
</style>
